body {
  margin: 0;
  box-sizing: border-box;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
}

/* MAIN CONTAINERS for ALL PAGES */
.gallery-main,
.archive-main,
.about-main {
  margin: 6% 15%;
}

.home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* TAGS */
h1 {
  text-align: right;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2 {
  text-align: center;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 1em;
}

h3 {
  font-weight: 400;
}

a {
  color: black;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
  color: #4395d1;
}

hr {
  height: 1px;
  background-color: black;
  border: none;
}

span {
  color: #4395d1;
}

/* classes for specific styling */
.dashed {
  height: 0px;
  border: dashed black 0.5px;
  background-color: white;
}

.blue {
  color: #4395d1;
}

/* TITLE UNDERLINES */
.hr {
  border-top: 1px solid black;
  width: 400px;
  margin-left: auto;
  margin-right: 0;
}

/* DESKTOP NAV STYLE */
.nav-desktop {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  padding-left: 0.5em;
}

nav a:hover {
  text-decoration: none;
}

.nav-link:hover {
  border-radius: 2em;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 15px 15px rgba(255, 255, 255, 0.75);
}

.nav-link-img:hover {
  border-radius: 3em;
  background-color: rgba(67, 149, 209, 0.75);
  box-shadow: 0 0 50px 50px rgba(67, 149, 209, 0.75);
}

.email {
  width: 2.75em;
  position: relative;
  bottom: 0;
}

.twitter {
  /* padding: 0.25em; */
  width: 2.5em;
  position: relative;
  bottom: 0;
}

.fwb-img {
  padding: 0.5em;
  padding-bottom: 1em;
}

nav ul {
  padding-left: 1em;
  list-style: none;
}

nav li {
  padding: 1em 0;
}

/* MOBILE NAV STYLE */

.burger-bar {
  display: flex;
  position: fixed;
  top: 0;
  padding: 1em;
  border-radius: 3em;
  background-color: rgba(67, 149, 209, 0.75);
  box-shadow: 0 0 30px 30px rgba(67, 149, 209, 0.75);
  z-index: 1;
}

.menu-hamburger {
  font-size: 2rem;
  color: fff;
  z-index: 1;
}

.nav-menu {
  padding: 1em;
  background-color: #4395d1;
  width: 70vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.navbar-toggle {
  width: 100%;
  background-color: #4395d1;
  display: flex;
  font-size: 2rem;
}

/* HOME CAROUSEL STYLING */
.home-mobile-logo {
  display: none;
}

.carousel-container {
  position: relative;
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  align-content: center;

  background-color: rgba(186, 198, 205, 0.9);
  box-shadow: 0 0 50px 50px rgba(186, 198, 205, 0.9);
  z-index: 0;

  border: solid blue 5 px;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.card {
  background-color: rgb(255, 255, 255, 0.5);
  background-image: url("./Components/media/bubble_logo_thick.png");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  filter: blur(10px);
  transition: opacity 0.3s ease-in-out;
}

.carousel-img {
  width: auto;
  height: 100%;
}

.carousel-img[data-loaded="true"] {
  opacity: 1;
}

.carousel-vid {
  width: auto;
  height: 100%;
  position: inherit;
  vertical-align: top;
}

.video-wrapper {
  position: relative;
  height: inherit;
  width: inherit;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.card-info {
  display: none;
}

.card:hover .card-info {
  display: flex;
  text-align: right;
  justify-content: right;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 1;

  z-index: 2;
  border-radius: 3em;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 50px 50px rgba(255, 255, 255, 0.8);
}

.card:hover {
  filter: none;
}

.card-info ul {
  list-style: none;
}

.card-info li {
  padding-top: 0.5em;
}

/* ABOUT STYLING */

.press-container {
  padding: 5% 8% 0 8%;
}

.press-details {
  display: flex;
  flex-direction: row;
}

.press-details p {
  padding-left: 1em;
}

.about-container {
  padding: 1% 4%;
  width: 60%;
  margin: 3em auto;
  border-radius: 5em;
  background-color: rgba(186, 198, 205, 0.9);
  box-shadow: 0 0 50px 50px rgba(186, 198, 205, 0.9);
  text-align: center;
}

.press-entry {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.press-entry a:hover {
  text-decoration: underline;
  color: #4395d1;
}

.about-logo {
  width: 75px;
  height: auto;
  display: block;
  margin: auto;
}

.blur {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  filter: blur(4px);
}

.attributions-container {
  width: 40%;
  margin: auto;
  text-align: center;
}

/* HOME CAROUSEL INSTRUCTIONS */
.controls-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  width: 75%;
  margin-top: 0.5em;
  margin-left: 0;
  margin-right: auto;
}

.home-static {
  position: fixed;
  bottom: 0;
  margin: auto;
  left: 0;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socials-right {
  position: fixed;
  bottom: 0;
  margin: auto;
  right: 0;
  padding: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socials-right img:hover {
  border-radius: 3em;
  background-color: rgba(67, 149, 209, 0.75);
  box-shadow: 0 0 15px 15px rgba(67, 149, 209, 0.75);
}

.socials-right ul {
  list-style: none;
}

.socials-right li {
  display: inline-block;
  padding-left: 1em;
}

.home-static p {
  text-align: right;
  padding: 0.5em;
}

button {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 1.5em;
  background-color: white;
  border: solid black 2px;
  width: 6em;
  height: 2.25em;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
}

button:hover {
  border-radius: 3em;
  background-color: rgba(67, 149, 209, 0.75);
  box-shadow: 0 0 10px 10px rgba(67, 149, 209, 0.75);
}

/* Mobile  */
.home-full {
  display: block;
  width: 90%;
  margin: 5% auto 0 auto;
}

.mobile-home-main p {
  padding: 3% 0;
  font-size: 20px;
}

.mobile-home-main ul {
  list-style: none;
}

/* ARCHIVE  */
.archive-container,
.about-body {
  padding: 5% 7%;
}

/* exhibitions */
.exhibition-title a {
  text-decoration: underline;
}

.exhibition-container {
  border: solid black 1px;
  border-radius: 3em;
  padding: 0.5em 0;
  margin-bottom: 1em;
}

.exhibition-title,
.artwork-record {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.75em;
}

.bottom-records {
  padding: 1em 1.75em;
}

.exhibition-details {
  display: flex;
  justify-content: space-between;
}

.exhibition-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exhibition-images img {
  width: 200px;
  height: 175px;
  object-fit: cover;
  object-position: 0 18%;
  padding: 1em;
  transition: 850ms;
}

.event-img:hover {
  transform: scale(3);
  transition: 850ms;
}

.exhibition-text {
  display: flex;
  align-items: right;
  flex-direction: column;
  padding-bottom: 1em;
}

.exhibition-text p {
  text-align: left;
  padding: 1em 1em 0 1em;
}

.detail-align {
  text-align: right;
}

/* exhibitions artworks */
.expand {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 1em 1.75em;
}

.expand button {
  font-weight: 400;
  font-size: 20px;
  color: #4395d1;
  background-color: white;
  border: none;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  cursor: pointer;
  text-align: right;
}

.expand button:hover {
  box-shadow: none;
}

.artwork-record h3,
.artwork-record p,
.artwork-record a,
.expand h3 {
  padding-right: 0.5em;
}

.artwork-record:hover {
  background-color: #bac6cd;
}

.art-displayed {
  display: flex;
  justify-content: left;
  align-items: center;
  max-width: 80%;
  flex-wrap: wrap;
}

/* only displays artwork on hover */
.hover-archive-img-container {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover-archive-img {
  opacity: 50%;
  width: 100%;
  height: auto;
}

.archive-vid {
  opacity: 50%;
  width: auto;
  height: 100%;
  vertical-align: top;
}

/* only displays link when hovered over whole artwork*/
.art-mouseover {
  display: none;
  justify-content: right;
  align-items: center;
}

.dropdown-logo {
  display: flex;
  margin: auto;
  width: 50px;
  height: auto;
}

.artwork-record:hover .art-mouseover {
  display: flex;
}

.art-mouseover a {
  color: #4395d1;
  text-decoration: underline;
}

.gallery-embed {
  margin: 2em;
  display: flex;

  width: 90%;
  height: 60vh;
}

@media only screen and (max-width: 900px) {
  .home-main {
    justify-content: flex-start;
  }

  .card-info {
    display: flex;
    text-align: right;
    justify-content: right;
    width: 80%;
    border-radius: 3em;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 35px 35px rgba(255, 255, 255, 0.8);
  }

  .socials-right {
    display: none;
  }

  .exhibition-images,
  .exhibition-title,
  .press-entry {
    flex-direction: column;
    align-items: center;
  }
  .press-container,
  .about-container,
  .attributions-container {
    padding: 3% 0;
  }

  .gallery-main,
  .archive-main,
  .about-main {
    margin: 5%;
  }

  .about-container,
  .attributions-container {
    width: 90%;
  }

  .press-details p {
    padding: 0.5em;
  }

  .press-entry {
    text-align: center;
  }

  .exhibition-images img {
    width: 90%;
    height: auto;
  }

  .art-displayed {
    max-width: 100%;
    padding-bottom: 1em;
  }

  .art-mouseover {
    display: flex;
  }

  .artwork-record {
    flex-wrap: wrap;
  }

  .exhibition-title {
    font-size: 14px;
  }

  .exhibition-title,
  .detail-align {
    text-align: center;
  }

  .hr {
    display: none;
  }

  .card {
    filter: none;
  }

  .home-mobile-logo {
    display: flex;
    margin: auto;
  }

  .event-img:hover {
    transform: scale(1);
    transition: 850ms;
  }
}

@media only screen and (max-width: 1000px) {
  .exhibition-details {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 1800px) {
  .gallery-main,
  .archive-main,
  .about-main {
    margin: 5% 25%;
  }

  .exhibition-text p {
    font-size: 20px;
  }
}
